<template>
  <div class="404">
    <div class="notFound">
      <div class="contain">
        <img src="@/assets/404.png" alt="" />
      </div>
      <p>你好像来到了没有知识的沙漠～</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped lang="less">
.notFound {
  margin: 80px auto;
  .contain {
    display: flex;
    justify-content: center;
    img {
      width: 400px;
      height: 300px;
      margin-bottom: 35px;
    }
  }
  p {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}
</style>
